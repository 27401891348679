/* Colors */
@font-face {
  font-family: 'Avenir Next';
  src: url(../fonts/AvenirNext/AvenirNextLTPro-Heavy.otf) format("truetype");
  font-weight: 700; }

@font-face {
  font-family: 'Avenir Next';
  src: url(../fonts/AvenirNext/AvenirNextLTPro-Bold.otf) format("truetype");
  font-weight: 600; }

@font-face {
  font-family: 'Avenir Next';
  src: url(../fonts/AvenirNext/AvenirNextLTPro-BoldItalic.otf) format("truetype");
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: 'Avenir Next';
  src: url(../fonts/AvenirNext/AvenirNextLTPro-Demi.otf) format("truetype");
  font-weight: 500; }

@font-face {
  font-family: 'Avenir Next';
  src: url(../fonts/AvenirNext/AvenirNextLTPro-DemiIt.otf) format("truetype");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: 'Avenir Next';
  src: url(../fonts/AvenirNext/AvenirNextLTPro-Medium.otf) format("truetype");
  font-weight: 400; }

@font-face {
  font-family: 'Avenir Next';
  src: url(../fonts/AvenirNext/AvenirNextLTPro-MediumItalic.otf) format("truetype");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'Avenir Next';
  src: url(../fonts/AvenirNext/AvenirNextLTPro-Regular.otf) format("truetype");
  font-weight: 300; }

@font-face {
  font-family: 'Avenir Next';
  src: url(../fonts/AvenirNext/AvenirNextLTPro-It.otf) format("truetype");
  font-weight: 300;
  font-style: italic; }

body {
  margin: 0;
  padding: 0;
  font-family: "Avenir Next", sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  overflow-x: hidden; }

h1 {
  font-size: 48px;
  font-weight: 600; }

h2 {
  font-size: 30px;
  font-weight: 500;
  color: #232C63; }

h3 {
  font-size: 24px;
  font-weight: 400; }

p {
  font-size: 18px;
  margin: 25px 0;
  color: #2B313A;
  line-height: 36px;
  font-weight: 400; }

a {
  text-decoration: none;
  transition: 0.3s; }
  a:hover, a:focus {
    text-decoration: none; }

.flex {
  display: flex; }
  .flex-center {
    align-items: center; }

ul {
  margin: 0;
  padding: 0; }
  ul li {
    list-style: none; }

.bullet-list {
  margin: inherit !important;
  padding: inherit !important; }
  .bullet-list li {
    margin-left: 25px;
    list-style: initial;
    color: #2B313A;
    font-size: 18px;
    line-height: 36px; }

.white {
  color: #ffffff; }

.brand-logo {
  margin-right: 10px; }

.brand a {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  font-style: italic; }
  .brand a:hover {
    opacity: 0.75; }

.brand .logo {
  margin-top: -3px;
  margin-right: 10px;
  height: 40px; }

.stats-title {
  font-size: 48px;
  font-weight: 500;
  color: #232C63;
  text-align: center; }

.stats-subtitle {
  height: 40px;
  font-size: 14px;
  color: #6F7785;
  font-weight: 500;
  text-transform: uppercase;
  padding: 0 20px;
  text-align: center;
  max-width: 200px;
  margin: auto; }

.stats-ind {
  font-size: 18px;
  font-weight: 400; }

.intro {
  padding-top: 90px;
  display: flex;
  align-items: center;
  color: #ffffff;
  position: relative;
  background: linear-gradient(135deg, #3D5AFE 0%, #6889FF 100%);
  min-height: 300px; }
  .intro-block {
    position: relative;
    margin-bottom: 0px; }
  .intro-block.center-section {
    margin-top: 75px; }
  .intro-block.left-section {
    margin-top: 75px; }
  .intro .btn {
    margin-top: 15px; }
  .intro h1, .intro h2, .intro h3, .intro p {
    color: #ffffff; }

.body-intro h3 {
  margin-bottom: 25px;
  color: #ffffff; }

.section-type {
  color: #CBD1DB;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500; }
  .section-type-highlight {
    color: #ffffff;
    font-weight: 600; }
  .section-type.dark {
    color: #6F7785; }
    .section-type.dark .section-type-highlight {
      color: #232C63;
      font-weight: 600; }

.card {
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 2px 25px 0px rgba(50, 50, 50, 0.1); }
  .card-header {
    padding: 40px;
    border-radius: 6px 6px 0 0; }
    .card-header h2 {
      color: #ffffff; }
    .card-header h3 {
      font-weight: 700;
      color: #ffffff; }
    .card-header-type {
      font-size: 14px;
      color: #CBD1DB;
      font-weight: 600;
      text-transform: uppercase; }
      .card-header-type .highlight {
        color: #ffffff;
        font-weight: 600; }
  .card-content {
    padding: 25px 40px; }

.alt-gradient {
  background: linear-gradient(135deg, #2A346E 0%, #465498 100%); }

.btn {
  font-size: 16px;
  font-weight: 600;
  border: none;
  color: #ffffff;
  background: linear-gradient(135deg, #21C090 0%, #46E1C3 100%);
  display: inline-flex;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.15);
  padding: 15px 20px;
  border-radius: 6px;
  position: relative;
  transition: 0.3s;
  margin-right: 15px; }
  .btn.blue {
    background: linear-gradient(135deg, #3D5AFE 0%, #6889FF 100%); }
  .btn.white {
    background: #ffffff;
    color: #232C63; }
    .btn.white:hover {
      color: #232C63; }
  .btn-link {
    color: #ffffff;
    font-size: 16px;
    font-weight: 600; }
    .btn-link:hover {
      opacity: 0.75; }
    .btn-link:hover {
      color: #ffffff; }
  .btn:hover {
    color: #ffffff;
    opacity: 1;
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.35);
    transform: scale(1.025); }

.body-section {
  display: flex;
  align-items: center; }
  .body-section .img-wrapper img {
    position: absolute; }

section {
  padding: 75px 0; }

.center-section {
  text-align: center;
  max-width: 700px;
  margin: auto; }
  .center-section .img-icon {
    margin: auto; }

.testimonial {
  padding: 50px;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 6px;
  margin-top: 25px; }
  .testimonial .quote {
    font-size: 72px;
    left: 50px;
    height: 30px;
    position: absolute;
    color: #ffffff;
    font-style: italic; }
  .testimonial p {
    margin: 50px 0 0 0;
    color: #ffffff;
    font-style: italic; }
  .testimonial-author {
    font-size: 12px;
    text-transform: uppercase;
    margin: 25px 0 0 0 !important;
    font-weight: 600;
    color: #6F7785; }

.bullet-list {
  font-size: 18px;
  line-height: 54px;
  font-weight: 400;
  color: #434E5D; }
  .bullet-list-item {
    display: flex;
    align-items: center; }
  .bullet-list-point {
    width: 25px;
    height: 25px;
    border-radius: 20px;
    margin-right: 15px;
    background: #E2E7EC;
    font-size: 14px;
    color: #3D5AFE;
    font-weight: 600;
    text-align: center;
    line-height: 25px; }

.img-icon {
  height: 50px;
  width: 50px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  margin-bottom: 35px; }
  .img-icon.blue {
    background: linear-gradient(135deg, #3D5AFE 0%, #6889FF 100%); }
  .img-icon.white {
    background: #ffffff; }
  .img-icon.green {
    background: #21C090; }
  .img-icon.red {
    background: #F24D6E; }
  .img-icon.orange {
    background: #FE973D; }
  .img-icon img {
    margin: auto; }

.light-theme section {
  padding: 150px 0 75px 0; }

.light-theme h1:first-child {
  margin-top: 0px; }

.light-theme h2 {
  color: #232C63; }

.light-theme h3 {
  font-weight: 600; }

.light-theme nav {
  border-bottom: solid 1px #E2E7EC; }

.light-theme footer {
  border-top: solid 1px #E2E7EC; }

.light-theme .brand a {
  color: #232C63; }
  .light-theme .brand a .logo .main {
    fill: #3D5AFE; }
  .light-theme .brand a .logo .alt {
    fill: #3D5AFE; }

.light-theme footer .brand a {
  color: #ffffff; }
  .light-theme footer .brand a .logo .main {
    fill: #ffffff; }
  .light-theme footer .brand a .logo .alt {
    fill: #ffffff; }

.light-theme .menu-link {
  color: #232C63; }
  .light-theme .menu-link::after {
    background: #232C63; }

@media (max-width: 992px) {
  .intro {
    padding: 50px 25px; }
  footer, section {
    padding: 50px 0; }
  .menu-link {
    margin: 0 2rem; }
  .hidden-med {
    display: none; }
  .body-section {
    height: auto !important; }
    .body-section .row {
      display: block; }
    .body-section .img-icon {
      margin-top: 25px; }
    .body-section h3 {
      text-align: left; }
    .body-section.home-chatbot .img-wrapper img {
      width: 300px;
      top: 0px;
      right: 50px; }
    .body-section.home-chatbot .row {
      display: flex; }
    .body-section.home-manager .img-wrapper img {
      width: 700px;
      top: 0;
      left: 50px; }
    .body-section.feature-ux .img-wrapper img {
      width: 300px;
      top: 0px;
      right: 50px; }
    .body-section.feature-commerce .feature-commerce-gear {
      left: 50px;
      width: 250px;
      top: 60px; }
    .body-section.feature-commerce .feature-commerce-food {
      width: 250px;
      top: -15px;
      left: 125px; }
    .body-section.feature-manager .img-wrapper img {
      width: 700px;
      top: 0px;
      right: 50px; }
  .card-content {
    padding: 25px 20px; }
  .stats-subtitle {
    margin-bottom: 25px;
    padding: 0px; } }

@media (max-width: 768px) {
  .stats-title, .stats-subtitle {
    text-align: center !important;
    margin: auto !important; }
  footer, section {
    padding: 50px 0; }
  section.intro {
    width: 100%;
    left: 0;
    border-radius: 0; }
  .card-header {
    text-align: center; }
  .hidden-xs {
    display: none; }
  .body-section {
    text-align: center; }
    .body-section .img-icon {
      margin: 25px auto 35px auto; }
  .intro {
    text-align: center;
    width: 120%;
    left: -10%; }
  .row.flex {
    display: block !important; } }
