/* Colors */
nav {
  font-size: 16px;
  font-weight: 500;
  z-index: 99;
  position: absolute;
  width: 100%;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1); }
  nav .nav-wrapper {
    height: 90px;
    display: flex;
    align-items: center; }
  nav a {
    color: #ffffff; }
    nav a:hover, nav a:focus {
      color: #ffffff;
      outline: none; }

.menu {
  display: none;
  flex-basis: auto;
  flex-grow: 1;
  justify-content: flex-end; }
  .menu-link {
    margin: 0 2.5rem;
    position: relative; }
    .menu-link.active:after {
      opacity: 1; }
    .menu-link:last-child {
      margin-right: 0; }
    .menu-link:after {
      content: '';
      transition: 0.3s;
      opacity: 0;
      position: absolute;
      bottom: -2px;
      left: 0;
      height: 2px;
      width: 100%;
      background: #ffffff; }
    .menu-link:hover {
      opacity: 1; }
      .menu-link:hover:after {
        opacity: 1; }

.mobile-menu {
  flex-basis: auto;
  flex-grow: 1;
  justify-content: flex-end; }
  .mobile-menu-icon {
    float: right;
    cursor: pointer;
    transition: 0.3s; }
    .mobile-menu-icon:hover {
      opacity: 0.5; }
  .mobile-menu-links {
    display: none;
    position: absolute;
    top: 75px;
    left: 0;
    background: #ffffff;
    border-radius: 15px;
    padding: 15px;
    z-index: 99;
    width: calc(100% - 30px);
    box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.15);
    margin: 0 15px; }
    .mobile-menu-links li {
      border-bottom: solid 1px #E2E7EC;
      display: block;
      padding: 10px 0; }
      .mobile-menu-links li:last-child {
        border-bottom: none; }
      .mobile-menu-links li a {
        color: #434E5D;
        cursor: pointer; }

@media (min-width: 600px) {
  nav .menu {
    display: flex; }
  nav .mobile-menu {
    display: none; } }
