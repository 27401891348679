/* Colors */
footer {
  padding: 75px 0;
  font-size: 16px;
  color: #2B313A;
  background: linear-gradient(135deg, #2A346E 0%, #465498 100%); }
  footer .footer-list li {
    margin-bottom: 5px;
    display: flex;
    align-items: center; }
    footer .footer-list li a {
      cursor: pointer;
      color: #ffffff; }
  footer .footer-list-title {
    color: #CBD1DB;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 15px !important; }
  footer .brand {
    margin-bottom: 35px; }
    footer .brand-logo path {
      fill: #ffffff; }
    footer .brand a {
      color: #ffffff; }
      footer .brand a:hover {
        color: #ffffff; }
  footer a {
    color: #2B313A;
    font-weight: 500; }
    footer a:hover {
      color: #434E5D; }
  footer .contact-info {
    position: absolute;
    color: #ffffff; }
  footer .copyright {
    text-align: center;
    color: #ffffff; }
  footer .divider {
    width: 100%;
    background: #ffffff;
    opacity: 0.1;
    height: 1px;
    margin: 25px 0; }

@media (max-width: 767px) {
  footer .brand, footer .footer-list {
    margin-bottom: 25px !important; } }
